.sv_main .sv_body {
  border-top: 5px solid #00b4f8 !important;
}
.sv_main input[type="button"],
.sv_main button {
  background-color: #00b4f8 !important;
  height: 50px;
  width: 100px;
}

.logo {
  width: 200px;
  height: 75px;
}
.sv_main .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: #00b4f8 !important;
  border-color: #00abf8 !important;
}
.sv_main .sv_q_rating_item .sv_q_rating_item_text:hover {
  border-color: #00b4f8 !important;
}
.sv_main .sv_q_other input:focus,
.sv_main .sv_q_text_root:focus,
.sv_main .sv_q_dropdown_control:focus,
.sv_main
  input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]):focus,
.sv_main select:focus,
.sv_main textarea:focus {
  border-color: #00b4f8 !important;
}

.survey_title {
  font-size: 22px;
  margin-left: 15px;
  margin-top: 26.9px;
}
.header {
  text-align: left;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.container {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
}
